import { Path, StrimApplication } from './types';

const createPath = (label: string, pattern: RegExp, application: StrimApplication) => ({
  label,
  pattern,
  application,
});

export const pathConfig: Path[] = [
  createPath('Strimetips', /^\/strimetips\/?/, 'Strim.WebApp'),
  createPath('Streaming', /^\/streaming\/?/, 'Strim.WebApp'),
  createPath('Signup', /^\/bli-kunde\/?/, 'Strim.Play'),
  createPath('Produkter', /^\/produkter\/?/, 'Strim.WebApp'),
  createPath('Abonnementer', /^\/abonnementer\/?/, 'Strim.WebApp'),
  createPath('Kunde', /^\/kunde\/?/, 'Strim.WebApp'),
  createPath('Hjelp', /^\/hjelp\/?/, 'Strim.WebApp'),
  createPath('Status', /^\/status\/?/, 'Strim.WebApp'),
  createPath('Strømmetjenester', /^\/strommetjenester\/?/, 'Strim.WebApp'),
  createPath('Kategori', /^\/kategori\/?/, 'Strim.Play'),
  createPath('App', /^\/app\/?/, 'Strim.Play'),
  createPath('Logout', /^\/logout\/?/, 'Strim.Play'),
  createPath('Email', /^\/email\/?/, 'Strim.Play'),
  createPath('Password', /^\/password\/?/, 'Strim.Play'),
  createPath('Package', /^\/package\/?/, 'Strim.Play'),
  createPath('Payment', /^\/payment\/?/, 'Strim.Play'),
  createPath('Guide', /^\/guide\/?/, 'Strim.Play'),
  createPath('Kampanje', /^\/kampanje\/?/, 'Strim.WebApp'),
  createPath('Tv-guide', /^\/tv-guide\/?/, 'Strim.Play'),
  createPath('Watch', /^\/watch\/?/, 'Strim.Play'),
  createPath('Vilkår', /^\/vilkar\/?$/, 'Strim.Play'),
  createPath('Personvern', /^\/personvern\/?$/, 'Strim.Play'),
  createPath('Hva er Strim', /^\/hva-er-strim\/?/, 'Strim.WebApp'),
  createPath('TV-pakker', /^\/tv-pakker\/?/, 'Strim.WebApp'),

  createPath('Obos', /^\/Obos\/?$/, 'Strim.WebApp'),
  createPath('Sidekart', /^\/sidekart\/?$/, 'Strim.Play'),
  createPath('AppleTV', /^\/appletv\/?$/, 'Strim.Play'),
  createPath('Min Konto', /^\/min-konto\/?$/, 'Strim.Play'),
  createPath('Endre telefonnummer', /^\/phone-number\/change\/?$/, 'Strim.Play'),
  createPath('Endre betaling', /^\/payment-method\/?$/, 'Strim.Play'),
  createPath('Ekstern aktivering callback', /^\/external-activation-callback\/?$/, 'Strim.Play'),
  createPath('Aktiver viaplay', /^\/aktiver-viaplay\/?$/, 'Strim.Play'),
  createPath('Aktiver Tv2', /^\/aktiver-tv2\/?$/, 'Strim.Play'),
  createPath('Aktiver Max', /^\/aktiver-max\/?$/, 'Strim.Play'),
  createPath('Aktiver TV', /^\/aktiver\/?$/, 'Strim.Play'),
  createPath('FAQ', /^\/faq\/?$/, 'Strim.Play'),
  createPath('Live', /^\/live\/?$/, 'Strim.Play'),
  createPath('Søk', /^\/search\/?$/, 'Strim.Play'),
  createPath('Policy / Personvern', /^\/policy\/?$/, 'Strim.Play'),
];

export const sharedStrimUrls = {
  personvern: '/personvern',
  signup: '/bli-kunde',
  search: '/search', // NB! also used in rikstv app
  account: '/min-konto',
} as const;
