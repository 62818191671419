/**
 * Mostly for easy injection in tests.
 * Define variables in ".env.test"
 * */
const getImportMetaEnv = () => {
  try {
    return import.meta.env as Record<string, string>;
  } catch (error) {
    return {};
  }
};

export const getEnvVariables = () => {
  const env = getImportMetaEnv();
  const appOverride = env.VITE_APP_CARD_CHECK_OVERRIDE === 'true';

  return {
    VITE_APP_NAME: env.VITE_APP_NAME,
    VITE_APP_ENV: env.VITE_APP_ENV,
    VITE_APP_VERSION: env.VITE_APP_VERSION,
    VITE_APP_GW_OVERRIDE: env.VITE_APP_GW_OVERRIDE,
    VITE_APP_STS_OVERRIDE: env.VITE_APP_STS_OVERRIDE,
    VITE_APP_CARD_CHECK_OVERRIDE: appOverride,
  };
};
