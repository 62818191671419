const encodeURLParameters = (data: { [key: string]: any }) => {
  const result: string[] = [];
  for (const d in data) {
    if (!data.hasOwnProperty(d)) {
      continue;
    }
    if (data[d] != null && data[d] !== '') result.push(`${d}=${encodeURIComponent(data[d])}`);
  }
  return result.join('&');
};

const getPortPart = () => {
  const port = window.location.port;
  if (port != null && port !== '' && port !== '80') {
    return ':' + port;
  } else {
    return '';
  }
};

export const isUrlAbsolute = (url: string) => /^https?:\/\//i.test(url);
export const addParameters = (url: string, params: Record<string, string>) => {
  const isAbsolute = isUrlAbsolute(url);
  const urlObj = new URL(isAbsolute ? url : window.location.origin + url);
  Object.entries(params).forEach(([key, value]) => urlObj.searchParams.append(key, value));
  return urlObj.toString().replace(!isAbsolute ? window.location.origin : '', '');
};

export const isUrlSameHost = (url: string, hostname = window.location.hostname) =>
  !isUrlAbsolute(url) || new URL(url).hostname === hostname;

const getHostWithPort = () => {
  return `${window.location.protocol}//${window.location.hostname}${getPortPart()}`;
};

const getHref = (): string => window.location.href;

const getLocationHash = (): string => window.location.hash;

export const toAbsolute = (url: string) => (isUrlAbsolute(url) ? url : window.location.origin + url);

export const toUrlFriendlyString = (value: string) => {
  return encodeURIComponent(value.toLowerCase().replaceAll(' ', '').replaceAll('.', ''));
};

export const addQueryParams = (url: string, params: Record<string, string | string[]>) => {
  const searchParams = new URLSearchParams();
  for (const key in params) {
    const values = params[key];
    if (Array.isArray(values)) {
      values.map(v => searchParams.append(key, v));
    } else {
      searchParams.append(key, values);
    }
  }
  return `${url}?${searchParams}`;
};

export { encodeURLParameters, getHostWithPort, getHref, getLocationHash, getPortPart };
