import { isRunningTests } from '../../utils/isRunningTests';
import { getEnvVariables } from '../getEnvVariables';
import { LD_ENV_KEYS } from '../launchDarkly';

import { SharedConfig } from './config.types';
import { ConfigEnvs, envs, localEnvs } from './config.utils';

const ENV = getEnvVariables();
const DEFAULT_LOCAL_ENV = localEnvs.UAT;
export const getLocalEnv = () => {
  const overriden = `${ENV?.VITE_APP_ENV}` || '';
  if (/^dev$/i.test(overriden)) return localEnvs.DEV;
  if (/^uat$/i.test(overriden)) return localEnvs.UAT;
  if (/^pt$/i.test(overriden)) return localEnvs.PT;
  if (/^prod$/i.test(overriden)) return localEnvs.PROD;

  if (!isRunningTests()) {
    console.warn(`No explicit local environment configured, using default (${DEFAULT_LOCAL_ENV})`);
  }
  return DEFAULT_LOCAL_ENV;
};

export const getSharedConfig = (env: ConfigEnvs): SharedConfig => ({
  // TODO(@tolu): use defined pkgJson for this instead
  appVersion: String(ENV?.VITE_APP_VERSION || ''),
  appName: String(ENV?.VITE_APP_NAME || ''),
  environment: env.currentEnv,
  apiBaseURL: env.forEnv({
    [envs.PROD]: 'https://client-api.rikstv.no/2',
    [envs.PT]: 'https://client-api.test.rikstv.no/2',
    [envs.TEST]: 'https://client-api.uat.rikstv.no/2',
    [envs.UAT]: 'https://client-api.uat.rikstv.no/2',
    [envs.DEV]: 'https://client-api.dev.rikstv.no/2',
  }),
  /**
   * The repository that controls API Gateway can be found here.
   * Config must match if we are to avoid Option-requests for all cross-origin requests.
   * https://git.rikstv.no/rikstv/sre/RiksTV.Config.Aws/-/tree/master/cfn_deployments/Web.Services.WebApiProxy/vars
   */
  urlRewrites: env.forEnv({
    [envs.PROD]: [
      { originalPrefix: 'https://gw.strim.no', newPrefix: '/api/strim-gateway' },
      // old client api origin
      { originalPrefix: 'https://api.rikstv.no/client', newPrefix: '/api/client' },
      // new client api origin
      { originalPrefix: 'https://client-api.rikstv.no', newPrefix: '/api/client' },
      { originalPrefix: 'https://play-api.rikstv.no/play', newPrefix: '/api/play' },

      { originalPrefix: 'https://contentlayout.rikstv.no', newPrefix: '/api/content-layout' },
      { originalPrefix: 'https://contentsearch.rikstv.no', newPrefix: '/api/content-search' },
    ],
    [envs.PT]: [
      { originalPrefix: 'https://gw.strim.no', newPrefix: '/api/strim-gateway' },
      // old client api origin
      { originalPrefix: 'https://api-pt.uat.rikstv.no/client', newPrefix: '/api/client' },
      // new client api origin
      { originalPrefix: 'https://client-api.test.rikstv.no', newPrefix: '/api/client' },
      { originalPrefix: 'https://play-api.rikstv.no/play', newPrefix: '/api/play' },

      { originalPrefix: 'https://contentlayout.test.rikstv.no', newPrefix: '/api/content-layout' },
      { originalPrefix: 'https://contentsearch.test.rikstv.no', newPrefix: '/api/content-search' },
    ],
    [envs.UAT]: [
      { originalPrefix: 'https://gw.uat.strim.no', newPrefix: '/api/strim-gateway' },
      // old client api origin
      { originalPrefix: 'https://api.uat.rikstv.no/client', newPrefix: '/api/client' },
      // new client api origin
      { originalPrefix: 'https://client-api.uat.rikstv.no', newPrefix: '/api/client' },
      { originalPrefix: 'https://play-api.uat.rikstv.no/play', newPrefix: '/api/play' },

      { originalPrefix: 'https://contentlayout.uat.rikstv.no', newPrefix: '/api/content-layout' },
      { originalPrefix: 'https://contentsearch.uat.rikstv.no', newPrefix: '/api/content-search' },
    ],
    [envs.TEST]: [],
    [envs.DEV]: [
      { originalPrefix: 'https://gw.dev.strim.no', newPrefix: '/api/strim-gateway' },
      // old client api origin
      { originalPrefix: 'https://api.uat.rikstv.no/client', newPrefix: '/api/client' },
      // new client api origin
      { originalPrefix: 'https://client-api.dev.rikstv.no', newPrefix: '/api/client' },
      { originalPrefix: 'https://play-api.uat.rikstv.no/play', newPrefix: '/api/play' },

      { originalPrefix: 'https://contentlayout.dev.rikstv.no', newPrefix: '/api/content-layout' },
      { originalPrefix: 'https://contentsearch.dev.rikstv.no', newPrefix: '/api/content-search' },
    ],
    [envs.LOCAL]: [],
  }),
  isDevelopment: env.forEnv({
    [envs.PROD]: false,
    [envs.PT]: true,
    [envs.TEST]: true,
    [envs.UAT]: true,
    [envs.DEV]: true,
    [envs.LOCAL]: true,
  }),
  player: {
    liveDelay: 10,
    liveBufferInSeconds: 7200,
    onTimeUpdateMs: 120000,
    seekBackSeconds: 10,
    seekForwardSeconds: 10,
    license:
      'sZP7IYe6T6fk0o0_0LP6CmzkCSPKFSai0le-TS0tImzo0DP1CL4lIDf_CLB6FOPlUY3zWokgbgjNIOf9flerCD0rCLerFSI13Sh-3u4l3mzr3S3KFSxgCSXKCL0k3uP636fVfK4_bQgZCYxNWoryIQXzImf90Sbz3L0i0Lfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3L5rTuBL3SeZ0LCiFOPeWok1dDrLYtA1Ioh6TgV6WQjlCDcEWt3zf6i6bKg7btxoFKrqf6i6btxZdDzpWK26FOPzdQ4qbQc1sD4ZFK3qWmPUFOPeWok1dDrLYt3qUYPlImf9DZfJf6i6WQjlCDcEWt3zf6i6UQ1gWtAVCYggb6rlWoz6Ymi6box7bZf9DZPEUQkV3mPUFOPLIDreYog-bwPgbt3NWo_6TGxZUDhVfKIgCYxkbK4LflNWYYz',
  },
  contentLayoutApiUrl: env.forEnv({
    [envs.PROD]: 'https://contentlayout.rikstv.no/1',
    [envs.PT]: 'https://contentlayout.test.rikstv.no/1',
    [envs.TEST]: 'https://contentlayout.uat.rikstv.no/1',
    [envs.UAT]: 'https://contentlayout.uat.rikstv.no/1',
    [envs.DEV]: 'https://contentlayout.dev.rikstv.no/1',
  }),
  contentSearchApiUrl: env.forEnv({
    [envs.PROD]: 'https://contentsearch.rikstv.no/1',
    [envs.PT]: 'https://contentsearch.test.rikstv.no/1',
    [envs.TEST]: 'https://contentsearch.uat.rikstv.no/1',
    [envs.UAT]: 'https://contentsearch.uat.rikstv.no/1',
    [envs.DEV]: 'https://contentsearch.dev.rikstv.no/1',
  }),
  tvodApiUrl: env.forEnv({
    [envs.PROD]: 'https://tvod.rikstv.no/1/',
    [envs.PT]: 'https://tvod.test.rikstv.no/1/',
    [envs.TEST]: 'https://tvod.uat.rikstv.no/1/',
    [envs.UAT]: 'https://tvod.uat.rikstv.no/1/',
    [envs.DEV]: 'https://tvod.dev.rikstv.no/1/',
  }),
  staticFilesBasePath: env.forEnv({
    [envs.PROD]: 'https://static.rikstv.no',
    [envs.PT]: 'https://static.rikstv.no',
    [envs.TEST]: 'https://static.uat.rikstv.no',
    [envs.UAT]: 'https://static.uat.rikstv.no',
    [envs.DEV]: 'https://static.dev.rikstv.no',
  }),
  // https://app.launchdarkly.com/default/production/features?q=web&tag=RiksTV.Apps.WebApps
  launchDarklyKey: env.forEnvWithDefault(
    {
      [envs.PROD]: LD_ENV_KEYS.prod,
      [envs.PT]: LD_ENV_KEYS.uat,
      [envs.UAT]: LD_ENV_KEYS.uat,
      [envs.LOCAL]: LD_ENV_KEYS.dev,
    },
    LD_ENV_KEYS.dev
  ),
});
