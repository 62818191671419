export const envs = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  UAT: 'UAT',
  TEST: 'TEST',
  PT: 'PT',
  PROD: 'PROD',
} as const;

export const localEnvs = {
  PROD: 'PROD',
  PT: 'PT',
  UAT: 'UAT',
  DEV: 'DEV',
} as const;

type EnvVariations<T = string> = {
  [key in keyof Omit<typeof envs, 'LOCAL'>]: T;
} & { LOCAL?: T };

export type AppEnvironment = keyof typeof envs;
type LocalAppEnvironment = keyof typeof localEnvs;

export class ConfigEnvs {
  readonly env: AppEnvironment;
  readonly localEnv?: LocalAppEnvironment;

  constructor(env: AppEnvironment, localEnv?: LocalAppEnvironment) {
    this.localEnv = localEnv;
    this.env = env;
  }

  get currentEnv(): AppEnvironment {
    // when env === "LOCAL" then localEnv is UAT/PT/etc
    return `${this.localEnv ?? this.env}`;
  }

  forEnv<T>(variations: EnvVariations<T>) {
    if (this.env === 'LOCAL') {
      if (!this.localEnv) {
        throw Error('Running in local environment without localEnv set.');
      }

      // Specific value for local dev environment
      if (variations.LOCAL != null) {
        return variations.LOCAL;
      }

      return variations[this.localEnv];
    }

    return variations[this.env];
  }

  forEnvWithDefault<T>(variations: Partial<EnvVariations<T>>, fallback: T) {
    return this.forEnv(variations as EnvVariations<T>) ?? fallback;
  }
}
