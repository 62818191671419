import getRiksTvConfig from './apps/config.rikstv';
import getStrimConfig from './apps/config.strim';
import { getEnvVariables } from './getEnvVariables';

const ENV = getEnvVariables();
const appName = ENV.VITE_APP_NAME;

if (appName == null) {
  throw new Error('Missing required "VITE_APP_NAME" variable');
}

const cfg = /strim/i.test(String(appName)) ? getStrimConfig() : getRiksTvConfig();
export const configEnvs = cfg.configEnvs;
export const AuthConfig = cfg.Config.auth;

export default cfg.Config;
